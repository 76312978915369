import axios from 'axios'

export default {
  segments() {
    return axios
      .get('/api/super_admin/segmentation_sets', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  updateAccess(segmentID, isPublic) {
    return axios
      .put(
        `/api/super_admin/segmentation_sets/${segmentID}`,
        {
          is_public: isPublic
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  },
  updateOrder(publicSegments) {
    return axios
      .put(
        '/api/super_admin/segmentation_sets/update_order',
        {
          segmentation_sets: publicSegments.map(ps => ({
            id: ps.id,
            segment_order: ps.segment_order
          }))
        },
        { handleErrors: true }
      )
      .then(() => {})
  }
}
