<template>
  <super-admin-page-card
    pageName="Manage Segmentations"
    iconClass="fa fa-pie-chart fa-lg"
  >
    <div>
      <b-tabs v-model="tabIndex">
        <b-tab>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">
              Manage Segmentation Access
            </h6>
          </template>

          <v-wait for="loadingSegments">
            <template slot="waiting">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="20"
                ></content-placeholders-text>
              </content-placeholders>
            </template>

            <b-row class="justify-content-end mb-4">
              <b-col sm="4">
                <b-form-group label="Search" :label-cols="3" :horizontal="true">
                  <b-form-input v-model="searchText"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              striped
              hover
              :fields="tableFields"
              :filter="searchText"
              :items="segmentations"
              small
              responsive
            >
              <template v-slot:cell(is_public)="data">
                <c-switch
                  type="text"
                  variant="success"
                  on="Yes"
                  off="No"
                  :pill="true"
                  size="sm"
                  :checked="data.item.is_public"
                  @change="updateAccess(data.item, $event)"
                />
              </template>
            </b-table>
          </v-wait>
        </b-tab>
        <b-tab>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">
              Manage Segmentation Order
            </h6>
          </template>

          <v-wait for="loadingSegments">
            <template slot="waiting">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="20"
                ></content-placeholders-text>
              </content-placeholders>
            </template>
            <div class="row">
              <div class="col-sm-6">
                <draggable
                  class="list-group"
                  tag="ul"
                  v-model="publicSegments"
                  @change="saveCardPosition"
                  @start="dragStart"
                  @end="dragEnd"
                  :forceFallback="true"
                  v-if="publicSegments.length"
                >
                  <div
                    v-for="(segment, index) in publicSegments"
                    class="list-group-item cursor-pointer"
                    :key="segment.id"
                  >
                    {{ segment.name }}
                    <div class="d-inline float-right index-icon px-2">
                      {{ index + 1 }}
                    </div>
                  </div>
                </draggable>
                <div v-else>
                  <div class="list-group">
                    <div class="list-group-item p-4">
                      No Public Groups to sort
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-wait>
        </b-tab>
      </b-tabs>
    </div>
  </super-admin-page-card>
</template>

<script>
import segmentationsAPI from '@/api/finapps/super_admin/segmentations'
import cSwitch from '@/components/Switch'
import draggable from 'vuedraggable'
import _ from 'lodash'

export default {
  name: 'SuperAdminSegmentations',
  components: {
    cSwitch,
    draggable
  },
  data() {
    return {
      tabIndex: 0,
      searchText: null,
      segmentations: [],
      tableFields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Segment Name', sortable: true },
        { key: 'fi_type', label: 'FI Type', sortable: true },
        { key: 'creator', label: 'Created By', sortable: true },
        { key: 'is_public', label: 'Public Access' }
      ]
    }
  },
  created() {
    this.loadInitData()
  },
  computed: {
    publicSegments: {
      get() {
        let publicSegments = this.segmentations.filter(ng => ng.is_public)
        return _.sortBy(publicSegments, ['segment_order', 'name'])
      },
      set(sortedSegments) {
        sortedSegments.forEach((segment, index) => {
          segment.segment_order = index + 1
        })
      }
    }
  },
  methods: {
    loadInitData() {
      this.$wait.start('loadingSegments')

      segmentationsAPI.segments().then(res => {
        this.segmentations = res.segmentation_sets
        this.$wait.end('loadingSegments')
      })
    },
    updateAccess(segment, state) {
      segmentationsAPI.updateAccess(segment.id, state).then(res => {
        segment.is_public = state
        segment.segment_order = res.segment.segment_order
        this.$toasted.global.action_success(
          'Segment access successfully modified.'
        )
      })
    },
    saveCardPosition() {
      segmentationsAPI.updateOrder(this.publicSegments).then(() => {
        this.$toasted.global.action_success(
          'Segment Order successfully updated.'
        )
      })
    },
    dragStart() {},
    dragEnd() {}
  }
}
</script>
<style lang="scss" scoped>
.index-icon {
  border-radius: 50%;
  font-weight: bold;
  color: white;
  background-color: #7b7878;
}
</style>
